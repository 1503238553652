import React, {useState, useEffect} from 'react';

import yel from '../assets/images/yel.png';
import c2 from '../assets/images/2c.png';

import {Cart} from "../components/Cart";
import {Progress} from "../components/Progress";
import {StepSex} from "../components/StepSex";
import {StepAge} from "../components/StepAge";
import {StepWeight} from "../components/StepWeight";
import {StepHeight} from "../components/StepHeight";
import {StepActivity} from "../components/StepActivity";
import {StepHealth} from "../components/StepHealth";
import {StepDiet} from "../components/StepDiet";
import {StepMeals} from "../components/StepMeals";
import {StepFinal} from "../components/StepFinal";
import {STEP_FINAL} from "../utils";

import { useShopify } from "../hooks"

const clearStorage = () => {
  localStorage.removeItem('gender')
  localStorage.removeItem('age')
  localStorage.removeItem('weight')
  localStorage.removeItem('step')
  localStorage.removeItem('feet')
  localStorage.removeItem('inches')
  localStorage.removeItem('activity')
  localStorage.removeItem('health')
  localStorage.removeItem('diet')
  localStorage.removeItem('meals')
}

export const Quiz = () => {

  const _gender = localStorage.getItem('gender')
  const _age = localStorage.getItem('age')
  const _weight = localStorage.getItem('weight')
  const _step = localStorage.getItem('step')
  const _feet = localStorage.getItem('feet')
  const _inches = localStorage.getItem('inches')
  const _activity = localStorage.getItem('activity')
  const _health = localStorage.getItem('health')
  const _diet = localStorage.getItem('diet')
  const _meals = localStorage.getItem('meals')

  const [gender, setGender] = useState(_gender || null);
  const [age, setAge] = useState(_age * 1 || '');
  const [weight, setWeight] = useState(_weight * 1 || '');
  const [step, setStep] = useState(parseInt(_step) || 1);
  const [feet, setFeet] = useState(_feet * 1 || '');
  const [inches, setInches] = useState(_inches * 1 || '');
  const [activity, setActivity] = useState(_activity * 1 || '');
  const [health, setHealth] = useState(_health * 1 || '');
  const [diet, setDiet] = useState(_diet * 1 || '');
  const [meals, setMeals] = useState(_meals * 1 || null);

  const {
    createShop,
    createCheckout,
    fetchProducts,
  } = useShopify();

  const data = {gender, age, weight, feet, inches, activity, health, diet, meals};

  useEffect(() => {
    createShop();
    fetchProducts();
    createCheckout();
  }, []);

  const handleBack = () => {
    setStep(step - 1);
    localStorage.setItem('step', (step - 1).toString())
  };

  const handleNext = () => {
    setStep(step + 1);
    localStorage.setItem('step', (step + 1).toString())
  };

  const handleChoseGender = _gender => {
    setGender(_gender);
    localStorage.setItem('gender', _gender)
    setTimeout(() => {
      setStep(step + 1);
      localStorage.setItem('step', (step + 1).toString())
    }, 300);
  };

  let shadowClass = 'shadow flex-box';
  let wrapClass = 'quiz-wrap overflow';

  if (step === STEP_FINAL) {
    shadowClass += ' shadow-final';
    wrapClass = 'quiz-wrap';
  }

  return (
    <div className={wrapClass}>
      <div className={shadowClass}>
        <Progress step={step} />
        <StepSex step={step} handleChoseGender={handleChoseGender} gender={gender} />
        <StepAge
          handleBack={handleBack}
          handleNext={handleNext}
          age={age}
          step={step}
          handleChoseAge={a => {
            setAge(a)
            localStorage.setItem('age', a)
          }}
        />
        <StepWeight
          handleBack={handleBack}
          handleNext={handleNext}
          weight={weight}
          step={step}
          handleChoseWeight={w => {
            setWeight(w)
            localStorage.setItem('weight', w)
          }}
        />
        <StepHeight
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
          handleChoseFeet={f => {
            setFeet(f)
            localStorage.setItem('feet', f)
          }}
          handleChoseInches={i => {
            setInches(i)
            localStorage.setItem('inches', i)
          }}
          feet={feet}
          inches={inches}
        />
        <StepActivity
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
          handleChoseActivity={a => {
            setActivity(a)
            localStorage.setItem('activity', a)
          }}
          activity={activity}
        />
        <StepHealth
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
          handleChoseHealthGoals={h => {
            setHealth(h)
            localStorage.setItem('health', h)
          }}
          health={health}
        />
        <StepDiet
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
          handleChoseDiet={d => {
            setDiet(d)
            localStorage.setItem('diet', d)
          }}
          diet={diet}
        />
        <StepMeals
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
          handleChoseMeals={m => {
            setMeals(m)
            localStorage.setItem('meals', m)
          }}
          meals={meals}
        />
        {step === STEP_FINAL && <StepFinal handleBack={handleBack} step={step} data={data} setStep={s => {
          setStep(s)
          localStorage.setItem('step', s)
        }} />}
      </div>
      {step === 1 && ([
        <img key="c1" src={yel} className="yel" alt=""/>,
        <img key="c2" src={c2} className="c2" alt=""/>
      ])}
      <Cart />
    </div>
  )
};
