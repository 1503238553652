import React, {useEffect} from 'react';
import {BuyBundle} from '../components/BuyBundle';
import {useShopify} from '../redux/ducks/shopify';
import {Cart} from '../components/Cart';
import {Progress} from '../components/Progress';
import {STEP_FINAL} from '../utils';
import {Link} from 'react-router-dom';

export const BundlePage = ({build}) => {

  const {
    createShop,
    createCheckout,
    fetchProducts,
  } = useShopify();

  useEffect(() => {
    createShop();
    fetchProducts();
    createCheckout();
  }, []);

  const getColoredText = () => {
    switch (build) {
      case 'Burn':
        return (
          <>
            <span className="colored-first">Burn Body Fat</span>
            <span className="colored-second">Burn Body Fat</span>
            <span className="colored-third">Burn Body Fat</span>
          </>
        )
      case 'Maintain':
        return (
          <>
            <span className="colored-first">Maintain & Improve</span>
            <span className="colored-second">Maintain & Improve</span>
            <span className="colored-third">Maintain & Improve</span>
          </>
        )
      default:
        return (
          <>
            <span className="colored-first">Build Lean Muscle</span>
            <span className="colored-second">Build Lean Muscle</span>
            <span className="colored-third">Build Lean Muscle</span>
          </>
        )
    }
  }

  return (
    <div className="quiz-wrap">
      <div className="shadow flex-box shadow-bundle-page">
        <Progress hideTitles={true} step={STEP_FINAL} />
        <div className="px-bundle">
          <div className="carte-wrap mb-5 d-block">
            <div className="colored carte mb-2">
              {getColoredText()}
            </div>
            <p className="text-center mb-2 hand-picked">
              Personally made for your needs
            </p>
          </div>
          <BuyBundle build={build} isPage={true}/>
          <div className="text-center mb-5">
            <a className="button filled" target="_blank" href="https://gettrusupps.com/collections/massy-arias">
              Visit our entire store
            </a>
          </div>
        </div>
        <Cart/>
      </div>
    </div>
  );
};
