import React from 'react';
import bundleImg from '../assets/images/bundles/buildButton.png';
import build1    from '../assets/images/protein.png';
import build2    from '../assets/images/creatine.png';
import build3    from '../assets/images/vitamin.png';
import build4    from '../assets/images/energy.png';
import {buildIds as ids} from '../utils';

export const BundleBuild = ({isPage, openModal, buyBundle}) => {

  const description = 'Building lean muscle mass requires a lot of hard work and dedication. The right products can get you to your goals a lot faster. Providing your body with lean plant protein and our ultra-pure Creatine guarantees you fuel your body with what it needs to produce lean muscle. Our Energy Pre-workout will get you through your entire workout, guaranteed. And finally, our Vitamin will provide you with the right amount of micronutrients to ensure you stay healthy and on the right path.';

  return (
    <div className="bundle-container">
      {!isPage && <p className="bundle-desc mb-80 show-tablet">
        {description}
      </p>}
      <div className="bundle-left">
        <img src={bundleImg} onClick={() => buyBundle({
          items: [ids.protein, ids.creatine, ids.vitamin, ids.energy],
          title: 'Build',
        })} alt=""/>
      </div>
      {isPage && <p className="bundle-desc mb-80 show-tablet">
        {description}
      </p>}
      <div className="bundle-right">
        <div className="carte-wrap">
          <div className="colored carte mb-2">
            <span className="colored-first">Recommended to you from us</span>
            <span className="colored-second">Recommended to you from us</span>
            <span className="colored-third">Recommended to you from us</span>
          </div>
          <p className="text-center mb-2 hand-picked">
            Hand picked by health experts
          </p>
        </div>
        <div className="bundle-items">

          <div className="bundle-item" onClick={() => openModal(ids.protein)}>
            <div className="bundle-item-img">
              <img src={build1} alt="" className="big"/>
            </div>
            <div className="bundle-item-text">
              <h4>Plant Protein</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

          <div className="bundle-item" onClick={() => openModal(ids.creatine)}>
            <div className="bundle-item-img">
              <img src={build2} alt=""/>
            </div>
            <div className="bundle-item-text">
              <h4>Creatine</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

          <div className="bundle-item" onClick={() => openModal(ids.vitamin)}>
            <div className="bundle-item-img">
              <img src={build3} alt=""/>
            </div>
            <div className="bundle-item-text">
              <h4>Vitamin</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

          <div className="bundle-item" onClick={() => openModal(ids.energy)}>
            <div className="bundle-item-img">
              <img src={build4} alt=""/>
            </div>
            <div className="bundle-item-text">
              <h4>Energy</h4>
              <p>Learn More Now</p>
              <span>Click Here</span>
            </div>
          </div>

        </div>
        <p className="bundle-desc pb-100 hide-tablet">
          {description}
        </p>
      </div>
    </div>
  )
};
