import React     from 'react';
import bundleImg from '../assets/images/bundles/maintainButton.png';
import build1    from '../assets/images/protein.png';
import build2    from '../assets/images/acv.png';
import build3    from '../assets/images/vitamin.png';
import build4    from '../assets/images/hydrate.png';
import {maintainIds as ids} from '../utils';

export const BundleMaintain = ({isPage, openModal, buyBundle}) => {

  const description = 'Reaching your fitness goals is great but maintaining a healthy lifestyle is even more important. Maintain your health requires that you regularly exercise and maintain a healthy diet. But in doing all this the body, especially your cells, get a little overworked. This is why we’ve made you a custom bundle designed to reinvigorate and rehydrate your entire body. Every single one of these products will help you stay in the best health of your life.';

  return (
    <div className="bundle-container">
      {!isPage && <p className="bundle-desc mb-80 show-tablet">
        {description}
      </p>}
      <div className="bundle-left">
        <img src={bundleImg} onClick={() => buyBundle({
          items: [ids.protein, ids.acv, ids.vitamin, ids.hydrate],
          title: 'Maintain',
        })} alt=""/>
      </div>
      {isPage && <p className="bundle-desc mb-80 show-tablet">
        {description}
      </p>}
      <div className="bundle-right">
        <div className="carte-wrap">
          <div className="colored carte mb-2">
            <span className="colored-first">Recommended to you from us</span>
            <span className="colored-second">Recommended to you from us</span>
            <span className="colored-third">Recommended to you from us</span>
          </div>
          <p className="text-center mb-2 hand-picked">
            Hand picked by health experts
          </p>
        </div>
        <div className="bundle-items">

          <div className="bundle-item" onClick={() => openModal(ids.protein)}>
            <div className="bundle-item-img">
              <img src={build1} alt="" className="big"/>
            </div>
            <div className="bundle-item-text">
              <h4>Protein</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

          <div className="bundle-item" onClick={() => openModal(ids.vitamin)}>
            <div className="bundle-item-img">
              <img src={build3} alt=""/>
            </div>
            <div className="bundle-item-text">
              <h4>Vitamin</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

          <div className="bundle-item" onClick={() => openModal(ids.acv)}>
            <div className="bundle-item-img">
              <img src={build2} alt=""/>
            </div>
            <div className="bundle-item-text">
              <h4>ACV</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

          <div className="bundle-item" onClick={() => openModal(ids.hydrate)}>
            <div className="bundle-item-img">
              <img src={build4} alt=""/>
            </div>
            <div className="bundle-item-text">
              <h4>Hydrate</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

        </div>
        <p className="bundle-desc pb-100 hide-tablet">
          {description}
        </p>
      </div>
    </div>
  )
};
