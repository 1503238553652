import React, {useState} from 'react';
import {burnIds, buildIds, maintainIds} from '../utils';
import {useShopify} from '../redux/ducks/shopify';
import img from '../assets/images/bundleModalImg.png'
import maintainBubble from '../assets/images/bundles/maintainBubble.png'
import buildBubble from '../assets/images/bundles/buildBubble.png'
import burnBubble from '../assets/images/bundles/burnBubble.png'

export const FlavorModal = ({bundle}) => {

  const products = bundle.items;
  const bundleTitle = bundle.title;

  const {
    checkoutState,
    addVariant,
    addDiscount,
  } = useShopify();

  const [items, setItems] = useState([])

  const [protein, setProtein] = useState('Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjc3MzU1NjI0MDQ2NQ==');
  const [energy, setEnergy] = useState('Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTM1ODUzMTg5NTM3Nw==');
  const [bcaa, setBcaa] = useState('Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjY1ODIyMDY0NjQ4MQ==');

  React.useEffect(() => {
    init()
  }, [])

  const init = () => {
    let _items = []
    products.forEach(p => {
      if (
        p.variants.length === 1 ||
        p.id === maintainIds.acv ||
        p.id === burnIds.metabolism
      ) {
        _items.push(p);
      }
    })
    setItems(_items)
  }

  const handleContinue = () => {
    let itemsToAdd = []

    let total = items.map(i => {
      return i.variants ? i.variants[0].id.toString() : i.id
    });
    if (bundleTitle === 'Build') {
      total.push(protein)
      total.push(energy)
    } else if (bundleTitle === 'Burn') {
      total.push(bcaa)
    } else {
      total.push(protein)
    }
    total.forEach(p => {
      itemsToAdd.push({
        variantId: p,
        quantity: 1
      })
    });
    const checkoutId = checkoutState.id;
    addVariant(checkoutId, itemsToAdd).then(() => {
      addDiscount(checkoutId, 'TRUCALBUNDLE12').then(() => {
        window.location.replace(checkoutState.webUrl)
      })
    });
  }

  const getById = (id) => {
    return products.find(i => i.id === id)
  }

  const getImg = () => {
    switch (bundleTitle) {
      case 'Maintain':
        return maintainBubble;
      case 'Build':
        return buildBubble
      default:
        return burnBubble
    }
  }

  return (
    <div>
      <h5 className="h5">The {bundleTitle} Bundle</h5>
      <img src={getImg()} alt="" className="bubble-img" />
      <p className="lets-choose">
        Let’s choose your flavors
        For this bundle :)
      </p>
      <div className="text-center flex-box align-center mt-2">
        {bundleTitle === 'Maintain' && (
          <div>
            <p className="mt-2">What <span className="protein">Protein Flavor </span> would you like?</p>
            <select
              name=""
              id=""
              value={protein}
              className="sel"
              onChange={(e) => setProtein(e.target.value)}
            >
              {getById(buildIds.protein).variants.map(variant => {
                return <option key={variant.id} value={variant.id}>{variant.title}</option>;
              })}
            </select>
          </div>
        )}
        {bundleTitle === 'Build' && (
          <div>
            <p className="mt-2">What <span className="protein">Protein Flavor </span> would you like?</p>
            <select
              name=""
              id=""
              className="sel"
              value={protein}
              onChange={(e) => setProtein(e.target.value)}
            >
              {getById(buildIds.protein).variants.map(variant => {
                return <option key={variant.id} value={variant.id}>{variant.title}</option>;
              })}
            </select>
            <p className="mt-2">What <span className="energy">Energy Flavor</span> would you like?</p>
            <select
              name=""
              id=""
              className="sel"
              value={energy}
              onChange={(e) => setEnergy(e.target.value)}
            >
              {getById(buildIds.energy).variants.map(variant => {
                return <option key={variant.id} value={variant.id}>{variant.title}</option>;
              })}
            </select>
          </div>
        )}
        {bundleTitle === 'Burn' && (
          <div>
            <p className="mt-2">What <span className="bcaa">BCAA Flavor</span> would you like?</p>
            <select
              name=""
              id=""
              value={bcaa}
              className="sel"
              onChange={(e) => setBcaa(e.target.value)}
            >
              {getById(burnIds.bcaa).variants.map(variant => {
                return <option key={variant.id} value={variant.id}>{variant.title}</option>;
              })}
            </select>
          </div>
        )}
      </div>
      <div className="buttons mt-4">
        <button className="button filled" onClick={handleContinue}>Continue</button>
      </div>

    </div>
  )
}
