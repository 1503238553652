import React     from 'react';
import bundleImg from '../assets/images/bundles/burnButton.png';
import build1    from '../assets/images/bcaa.png';
import build2    from '../assets/images/meta.png';
import build3    from '../assets/images/acv.png';
import build4    from '../assets/images/detox.png';
import {burnIds as ids} from '../utils';

export const BundleBurn = ({isPage, openModal, buyBundle}) => {

  const description = 'If your goal is to burn fat, then this bundle is perfect for you. The main reason: Metabolism, our advanced fat loss supplement. The secret to eliminating body fat is to burn more than you consume. This requires intense dieting and long cardio sessions. But what if we told you that Metabolism is clinically proven to reverse the fat gene expression and trigger the body to burn more fat all while blocking any new fat from storing in your body? That is exactly what Metabolism does. But the benefits don\'t stop there. Combining Metabolism with ACV, our powerful and pure Apple Cider Vinegar supplement in addition to our Detox, which will help detox your body and reduce bloating, you’ll be sure to reach your weight loss goals in no time.';

  return (
    <div className="bundle-container">
      {!isPage && <p className="bundle-desc mb-80 show-tablet">
        {description}
      </p>}
      <div className="bundle-left">
        <img src={bundleImg} onClick={() => buyBundle({
          items: [ids.bcaa, ids.metabolism, ids.acv, ids.detox],
          title: 'Burn',
        })} alt=""/>
      </div>
      {isPage && <p className="bundle-desc mb-80 show-tablet">
        {description}
      </p>}
      <div className="bundle-right">
        <div className="carte-wrap">
          <div className="colored carte mb-2">
            <span className="colored-first">Recommended to you from us</span>
            <span className="colored-second">Recommended to you from us</span>
            <span className="colored-third">Recommended to you from us</span>
          </div>
          <p className="text-center mb-2 hand-picked">
            Hand picked by health experts
          </p>
        </div>
        <div className="bundle-items">

          <div className="bundle-item" onClick={() => openModal(ids.bcaa)}>
            <div className="bundle-item-img">
              <img src={build1} alt="" className="big"/>
            </div>
            <div className="bundle-item-text">
              <h4>BCAA</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

          <div className="bundle-item" onClick={() => openModal(ids.metabolism)}>
            <div className="bundle-item-img">
              <img src={build2} alt=""/>
            </div>
            <div className="bundle-item-text">
              <h4>Metabolism</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

          <div className="bundle-item" onClick={() => openModal(ids.acv)}>
            <div className="bundle-item-img">
              <img src={build3} alt=""/>
            </div>
            <div className="bundle-item-text">
              <h4>ACV</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

          <div className="bundle-item" onClick={() => openModal(ids.detox)}>
            <div className="bundle-item-img">
              <img src={build4} alt=""/>
            </div>
            <div className="bundle-item-text">
              <h4>Detox</h4>
              <p>Learn More Now</p>
              <span className="cursor-pointer">Click Here</span>
            </div>
          </div>

        </div>
        <p className="bundle-desc pb-100 hide-tablet">
          {description}
        </p>
      </div>
    </div>
  )
};
