import React, {useEffect, useState} from 'react';
import {BundleBuild} from "./BundleBuild";
import {BundleBurn} from "./BundleBurn";
import {BundleMaintain} from "./BundleMaintain";
import {getDescription} from './getProductsDescription';
import {useShopify} from "../hooks";
import Modal from 'react-modal';
import {buildIds, burnIds, maintainIds} from '../utils';
import {FlavorModal} from './FlavorModal';

const ids = {...buildIds, ...burnIds, ...maintainIds};

Modal.setAppElement('#root');

const customStyles = {
  content: {
    inset: 'auto',
    left: 'auto',
    right: 'auto',
    top: 'auto',
    bottom: 'auto',
    position: 'relative',
    borderRadius: '40px 40px 0 40px',
    padding: '50px 20px',
    margin: '50px auto',
  },
};

export const BuyBundle = ({build, isPage, data}) => {

  const {
    product,
    fetchProduct,
    openCart,
    checkoutState,
    addVariant,
    fetchSingleProduct,
  } = useShopify();

  const [variant, setVariant] = useState('');
  const [bundle, setBundle] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [flavorModal, setFlavorModal] = useState(false);
  const [localProduct, setLocalProduct] = useState(product);
  const quantity = 1;


  const handleCloseModal = () => {
    setIsOpen(false);
    setVariant('');
  };

  useEffect(() => {
    setLocalProduct(product);
  }, [product]);


  const changeSize = quantity => {
    const sizeId = variant || (localProduct.variants ? localProduct.variants[0].id.toString() : localProduct.id);
    const lineItemsToAdd = [
      {variantId: sizeId, quantity: parseInt(quantity, 10)},
    ];
    const checkoutId = checkoutState.id;
    addVariant(checkoutId, lineItemsToAdd);
    setVariant('');
    openCart();
  };

  const addToCart = () => {
    setIsOpen(false);
    changeSize(quantity);
  };

  const openModal = id => {
    fetchProduct(id).then(() => {
      setIsOpen(true);
    });
  };

  const buyBundle = async ({items, title}) => {
    const products = await Promise.all(items.map(id => fetchSingleProduct(id)));
    setBundle({items: products, title});
    setFlavorModal(true);
  };

  const getBundle = () => {
    let switchVar;
    if (isPage) {
      switchVar = build;
    } else {
      switchVar = data.health;
    }
    switch (switchVar) {
      case 'Build':
      case 300:
        return <BundleBuild isPage={isPage} buyBundle={buyBundle} openModal={openModal}/>;
      case 'Burn':
      case -300:
        return <BundleBurn isPage={isPage} buyBundle={buyBundle} openModal={openModal}/>;
      case 'Maintain':
      case 0:
      default:
        return <BundleMaintain isPage={isPage} buyBundle={buyBundle} openModal={openModal}/>;
    }
  };

  const handleCloseFlavorModal = () => {
    setFlavorModal(false);
  };

  return (
    <div>
      {getBundle()}
      <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="Modal" style={customStyles}> <span onClick={handleCloseModal} className="modal-close">Dismiss</span>
      <div className="modal-content">
        {modalIsOpen && (
          <div className='modal-img-wrap'>
            <img src={localProduct.images[0]?.src || ''} alt=""/>
          </div>
        )}
        <div className="flex">
          <h2 className="modal-product-title mb-2">{localProduct?.title || 'Empty'}</h2>{getDescription(localProduct.id, ids)}
        </div>
      </div>
      <div className="text-center flex-box align-center mt-4">
        {!!localProduct.variants && localProduct.variants.length > 1 &&
        <select className="sel" name="" id="" value={variant} onChange={(e) => {
          setVariant(e.target.value);
        }}>
          {localProduct.variants.map(variant => {
            return <option key={variant.id} value={variant.id}>{variant.title}</option>;
          })}
        </select>}
        <button className="button filled mt-2" onClick={addToCart}>Buy This Product</button>
      </div>
    </Modal>
      <Modal
        isOpen={flavorModal}
        onRequestClose={handleCloseFlavorModal}
        contentLabel="Modal" style={customStyles}>
        <span
          onClick={handleCloseFlavorModal}
          className="modal-close">
          Dismiss
        </span>
        <FlavorModal bundle={bundle}/>
    </Modal>
    </div>
  );
};
