import React, {useState} from 'react';
import {Circle} from "./Circle";
import {activityOptions, calculate, dietOptions, getStepClass, healthOptions, STEP_FINAL} from "../utils";
import Modal from 'react-modal';
import {Video} from './Video';
import {BuyBundle} from './BuyBundle';

Modal.setAppElement('#root');

const selectionsStyles = {
  content: {
    inset: 'auto',
    left: 'auto',
    right: 'auto',
    top: 'auto',
    bottom: 'auto',
    position: 'relative',
    borderRadius: 0,
    padding: '40px 10px',
    margin: '0 auto',
  },
};

export const StepFinal = ({data, step, setStep}) => {

  const {total, protein, fat, carbs, meals, result} = calculate(data);

  const [selections, setSelections] = useState(false);

  const getVideoId = () => {
    switch (data.health) {
      case 300:
        return 512884117;
      case -300:
        return 512880016;
      case 0:
      default:
        return 512882923;
    }
  }

  const SelectionsLayout = () => {
    return (
      <div className="fw-700">
        <h3 className="selections-title"><span>Your Selections:</span></h3>
        <ul className="selections-cont">
          <li className="selections-item age">
            <span className="selections-item-title">Age</span> <span>{data.age}</span>
          </li>
          <li className="selections-item">
            <span className="selections-item-title">Weight</span> <span>{data.weight} lbs.</span>
          </li>
          <li className="selections-item">
            <span className="selections-item-title">Health Goal:</span> <span>{healthOptions.find(o => o.value == data.health)?.text} </span>
          </li>
          <li className="selections-item">
            <span className="selections-item-title">Height</span> <span>{data.feet}’ {data.inches || 0}”</span>
          </li>
          <li className="selections-item">
            <span className="selections-item-title">Diet Style:</span> <span>{dietOptions.find(o => o.value == data.diet)?.text} </span>
          </li>
          <li className="selections-item">
            <span className="selections-item-title">Activity</span> <span>{activityOptions.find(o => o.value == data.activity)?.text}</span>
          </li>
          <li className="selections-item">
            <span className="selections-item-title">No. Of Meals:</span> <span>{data.meals} </span>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <div className={getStepClass(step, STEP_FINAL) + ' step-final'}>
      <div className="step-title">
        <div className="colored">
          <span className="colored-first">Results.</span> <span className="colored-second">Results.</span> <span className="colored-third">Results.</span>
        </div>
        <br/> <a href="#bundle" className="button filled sm-w-100">View your bundle</a>
      </div>
      <div className="step-content">
        <div className="res-circles">
          <Circle label={`Protein`} value={protein} total={total}/> <Circle label={`Carbs`} value={carbs} total={total}/> <Circle label={`Fat`} value={fat} total={total}/>
        </div>
        <div className="final-video">
          <Video id={getVideoId()} />
        </div>
        <div className="calorie-intake">
          Your Total Daily Calorie Intake:
          <div className="colored">
            <span className="colored-first">{result}</span> <span className="colored-second">{result}</span> <span className="colored-third">{result}</span>
          </div>
        </div>
        <div className="meal-cards">
          {meals}
        </div>
        <div className="edit show-tablet">
          <button onClick={() => setSelections(true)} className="button filled">Edit</button>
        </div>
        <div className="selections fw-700">
            <SelectionsLayout />
          <div className="edit">
            <button onClick={() => setStep(1)} className="button filled">Edit</button>
          </div>
          <h2 className="bundle-title" id="bundle">
            Your custom bundle </h2>
          <p className="mt-2 bundle-subtitle">Click on the product name or product circle for more details.</p>
        </div>
        <BuyBundle data={data} />
      </div>
      <Modal
        isOpen={selections}
        onRequestClose={() => setSelections(false)}
        contentLabel="Modal"
        style={selectionsStyles}
      >
        <div className="selections-modal">
          <SelectionsLayout />
          <div className="buttons">
            <button onClick={() => setSelections(false)} className="button filled">Keep As Is</button>
            <button onClick={() => setStep(1)} className="button filled">Edit</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
