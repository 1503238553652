import React from 'react';

export const getDescription = (id, ids) => {
  switch (id) {
    case ids.protein:
      return (
        <div>
          <p>
            TRU Protein is the all-natural and delicious plant-based protein powder built to deliver your body the protein, amino acids, and micronutrients needed to get the most out of your healthy
            lifestyle. </p>
          <ul className="check">
            <li><span>✓</span> 20 grams of protein</li>
            <li><span>✓</span> 27 vitamins and minerals</li>
            <li><span>✓</span> 100 calories per serving</li>
          </ul>
        </div>
      )
    case ids.bcaa:
      return (
        <p>BCAA (Branched Chain Amino Acids) are amino acids that contribute to muscular performance and endurance. They're also great for muscle recovery and fat loss. The best part is that our BCAAs are 100% Vegan.</p>
      )
    case ids.metabolism:
      return (
        <p>TRU Metabolism features a clinical dosage of Bilberry Fruit Extract. This shows to reduce fat storage at the cellular level. If you have a solid workout regimen and nutrition plan in place, you will lose fat faster than ever before. The rest of our natural formula uses an innovative combination of natural ingredients. These ingredients help manage appetite, maximize all-day energy levels, and enhance your mood. We can do all this without using artificial stimulants and harmful ingredients.</p>
      )
    case ids.acv:
      return (
        <p>TRU ACV gives you the incredible, fat-burning, benefits inside a vegan veggie capsule. Skip that dreaded “morning shot” of liquid apple cider vinegar. Protect your teeth from painful cavities caused by other Apple Cider Vinegars. Our PlantCap® technology delivers straight to your gut where your body needs it most. This is the most advance Apple Cider Vinegar, period!</p>
      )
    case ids.detox:
      return (
        <p>TRU Detox provides you all the amazing benefits Activated Charcoal has to offer! By using activated charcoal you can achieve a full body cleanse! Activated charcoal binds and absorbs small toxins out of your GI tract. It also helps absorb gas molecules to help reduce bloating and gas.</p>
      )
    case ids.creatine:
      return (
        <p>Creapure® has shown to be safe and effective with training. It can decrease muscle lactic acid accumulation and fatigue by 70%. It's clinically proven to increase strength and endurance by 30%. Creatine can also increases brain health and eliminates water retention and bloating.</p>
      )
    case ids.energy:
      return (
        <p>TRU Energy is our natural pre-workout. It's designed to give you exactly the right ingredients to have a perfect workout, every time! It uses a perfect symphony of ingredients to elevate your energy levels. It can increase strength and extend your endurance. It improves your fat-burning abilities.</p>
      )
    case ids.vitamin:
      return (
        <p>TRU Vitamin is the daily super formula! It provides so many essential nutrients that optimize our body. Improve your metabolism, immunity, recovery, and even joint health. This is the most advanced Multi-Vitamin ever made!</p>
      )
    case ids.hydrate:
      return (
        <p>Maintaining proper hydration will increase exercise and activity performance by preventing muscle cramping. Even more, is the importance of hydration throughout the day. Staying hydrated will increase fat loss and energy levels. It will also help with appetite-control and digestion.</p>
      )
  }
}
